@import '_colors';

.outerBoundary {
	overflow-x: auto;
	height: 100%;
	background: $darkBlue; /* fallback for old browsers */
	background: -webkit-linear-gradient(to top, $darkSky, $darkBlue); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to top,
		$darkSky,
		$darkBlue
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.main {
	grid-area: timeline;
	min-width: 1680px;
}

.line {
	position: absolute;
	width: 1px;
	height: 310px;
}

.herdTestSwimlane {
	height: 40px;
	background-color: $lightSky;
}
