@font-face {
	font-family: 'Booster FY';
	src: url('assets/fonts/BoosterNextFY-Thin.eot');
	src: url('assets/fonts/BoosterNextFY-Thin.eot#iefix') format('embedded-opentype'),
		url('assets/fonts/BoosterNextFY-Thin.ttf') format('truetype'),
		url('assets/fonts/BoosterNextFY-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Booster FY';
	src: url('assets/fonts/BoosterNextFY-Light.eot');
	src: url('assets/fonts/BoosterNextFY-Light.eot#iefix') format('embedded-opentype'),
		url('assets/fonts/BoosterNextFY-Light.ttf') format('truetype'),
		url('assets/fonts/BoosterNextFY-Light.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Booster FY';
	src: url('assets/fonts/BoosterNextFY-Regular.eot');
	src: url('assets/fonts/BoosterNextFY-Regular.eot#iefix') format('embedded-opentype'),
		url('assets/fonts/BoosterNextFY-Regular.ttf') format('truetype'),
		url('assets/fonts/BoosterNextFY-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Booster FY';
	src: url('assets/fonts/BoosterNextFY-Medium.eot');
	src: url('assets/fonts/BoosterNextFY-Medium.eot#iefix') format('embedded-opentype'),
		url('assets/fonts/BoosterNextFY-Medium.ttf') format('truetype'),
		url('assets/fonts/BoosterNextFY-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Booster FY';
	src: url('assets/fonts/BoosterNextFY-Bold.eot');
	src: url('assets/fonts/BoosterNextFY-Bold.eot#iefix') format('embedded-opentype'),
		url('assets/fonts/BoosterNextFY-Bold.ttf') format('truetype'),
		url('assets/fonts/BoosterNextFY-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Booster FY';
	src: url('assets/fonts/BoosterNextFY-Black.eot');
	src: url('assets/fonts/BoosterNextFY-Black.eot#iefix') format('embedded-opentype'),
		url('assets/fonts/BoosterNextFY-Black.ttf') format('truetype'),
		url('assets/fonts/BoosterNextFY-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}
