body {
	background-color: #edf3fa;
	margin: 0;
}

html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

.hidden {
	display: none;
}
