@import '_components';
@import '_colors';

.main {
	position: sticky;
	display: inline;
	left: 0.5rem;
	margin-left: 1rem;
}

.translucent {
	position: absolute;
	display: inline;
	left: -1rem;
	margin-left: 0;

	height: 30px;
	width: 9rem;

	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.85+92,0+100 */
	background: -moz-linear-gradient(
		left,
		rgba(255, 255, 255, 0.85) 0%,
		rgba(255, 255, 255, 0.85) 92%,
		rgba(255, 255, 255, 0) 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		left,
		rgba(255, 255, 255, 0.85) 0%,
		rgba(255, 255, 255, 0.85) 92%,
		rgba(255, 255, 255, 0) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0.85) 0%,
		rgba(255, 255, 255, 0.85) 92%,
		rgba(255, 255, 255, 0) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
}

.controlButton {
	@extend .smallOutlineButton;
	width: 2rem;
	margin: 2px 0;
	position: relative;
	outline: none;
}

.leftArrow {
	@extend .controlButton;
	background: $white url('./images/left-arrow-context-light.svg') no-repeat center center / 60%;

	&:active {
		background: $darkBlue url('./images/left-arrow-context-dark.svg') no-repeat center center / 60%;
	}
}

.rightArrow {
	@extend .controlButton;
	background: $white url('./images/right-arrow-context-light.svg') no-repeat center center / 60%;

	&:active {
		background: $darkBlue url('./images/right-arrow-context-dark.svg') no-repeat center center / 60%;
	}
}

.current {
	@extend .controlButton;
	margin: 2px 4px;
	background: $white url('./images/point-context-light.svg') no-repeat center center / 45%;

	&:active {
		background: $darkBlue url('./images/point-context-dark.svg') no-repeat center center / 45%;
	}
}
