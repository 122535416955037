.minda-switch {
	align-self: center;
	padding: 1rem;
	color: #001e62;
}

a {
	color: #001e62;
}

a:hover {
	color: #009cde;
}
