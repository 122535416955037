@import '_colors.scss';

.main {
	color: $darkBlue;

	h3,
	h4 {
		margin: 0;
		padding: 0;
		margin-bottom: 1rem;
	}

	.closeButton {
		position: absolute;
		right: 16px;
		top: 16px;
		width: 16px;
		height: 16px;
	}

	.calendar {
		display: flex;
		flex-direction: row;

		.icon {
			color: $skyBlue;
			width: 24px;
			height: 24px;
			margin-right: 16px;
			margin-bottom: 22px;
		}

		.occurred {
			display: flex;
			flex-direction: column;
		}

		.occurredLabel {
			color: $skyBlue;
			margin: 0;
		}

		.occurredDate {
			margin: 0;
		}
	}

	.modalDetailItem {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $blueWhite;
		height: 40px;

		.detailItemLabel {
			color: $gray;
			margin-left: 8px;
		}

		.detailItemValue {
			text-align: end;
		}
	}
}
