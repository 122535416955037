.loading-animation {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
}
.loading-animation div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 51px;
	height: 51px;
	margin: 6px;
	border: 6px solid rgba(255, 255, 255, 0.5);
	border-radius: 50%;
	animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
}
.loading-animation div:nth-child(1) {
	animation-delay: -0.45s;
}
.loading-animation div:nth-child(2) {
	animation-delay: -0.3s;
}
.loading-animation div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes loading-animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
