// https://xd.adobe.com/spec/01b8222b-ffff-4b01-6bbd-64965667b62f-7789/screen/561746fb-37f9-482f-9cdd-ebf8cccc578a/Colour/
$darkBlue: #001e62;
$gray: #54575a;
$darkSky: #007cbe;
$skyBlue: #009cde;
$lightSky: #cbdbef;
$blueWhite: #edf3fa;
$white: white;
$success: #18ccb8;
$failure: #f96f69;
