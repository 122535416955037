@import '_colors.scss';

header {
	background-color: white;

	display: grid;
	grid-template-areas: 'search-box nav-box';
	grid-template-columns: auto 1fr;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.search {
	grid-area: search-box;
	background-color: $darkBlue;
	color: $white;
}

.nav-box {
	grid-area: nav-box;
}
