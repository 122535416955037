.grid-container {
	max-width: 1600px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	margin: 0 auto;
	justify-content: center;
}

.tile {
	border-radius: 0.25rem;
	color: #001e62;
	min-height: 274px;
	max-height: 274px;
	min-width: 300px;
	align-self: stretch;
}

.tile-ready {
	background-color: #ffffff;
	-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
		0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	-moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
		0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
		0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.tile.tile-not-ready {
	background-color: #dde3ea;
	display: grid;
	grid: 1fr;
	justify-content: center;
	align-content: center;
}

@keyframes expand {
	from {
		opacity: 0.7;
		transform: scaleX(0.5);
	}
	to {
		opacity: 1;
		transform: scaleX(1);
	}
}

@-webkit-keyframes expand {
	from {
		opacity: 0.7;
		transform: scaleX(0.5);
	}
	to {
		opacity: 1;
		transform: scaleX(1);
	}
}

@-moz-keyframes expand {
	from {
		opacity: 0.7;
		transform: scaleX(0.5);
	}
	to {
		opacity: 1;
		transform: scaleX(1);
	}
}

.tile-detail-view {
	grid-column: span 2;
	max-width: none;
	max-height: none;
	animation: expand 0.3s cubic-bezier(0.18, 0.89, 0, 1.12);
}

@media (max-width: 374px) {
	/* Have a narrower gap on portrait of small devices (e.g. iPhone 5). */
	.grid-container {
		grid-row-gap: 0.5rem;
	}
	.tile {
		min-height: 253px;
	}
}

/* Need to know if we are in single column mode.
The breakpoint will be:
- the 2 tiles at a minimum width of 300px, plus
- the 3 gaps needed to space the 2 tiles, plus
- the width of a scroll-bar (assume 17px), _minus_
- the 1 pixel to be "less than".
 */
@media (max-width: 665px) {
	/* When the grid is in single column mode. */
	/* The grid rows can grow in height. */
	.grid-container {
		grid-auto-rows: unset;
	}
	.tile {
		max-height: none;
	}
	/* The tile cannot span more than one column if only one column is visible */
	.tile-detail-view {
		grid-column: span 1;
	}
}

/* Only show a maximum of 4 columns on screens larger than iPad 1024px.*/
@media (min-width: 1025px) {
	.grid-container {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}
