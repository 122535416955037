.landing-container {
	display: grid;
	grid-template-areas: 'header' 'summary' 'timeline' 'content' 'footer';
	grid-template-columns: 1fr;
	grid-template-rows: auto auto auto 1fr auto;
	min-height: 100vh;
}

header {
	grid-area: header;
}

main {
	grid-area: content;
	margin: 0.5rem;
}

footer {
	grid-area: footer;
	display: flex;
	flex-direction: column;
	margin-top: 6rem;
}
.copyright {
	align-self: center;
	font-size: 11px;
	padding: 1rem;
	color: #001e62;
}

@media (min-width: 375px) {
	main {
		margin: 0.5rem 1rem;
	}
}
