@import '_colors.scss';

.main {
	display: grid;
	position: relative;
	grid-template-columns: 10rem auto;
	grid-auto-rows: 40px;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.1);
	color: $blueWhite;
	margin-top: 5px;
}

.selected {
	background-color: rgba(255, 255, 255, 0.2);
}

.name {
	color: $lightSky;
	margin-left: 1rem;
	position: sticky;
	left: 0.5rem;
	z-index: 100;
}

.eventContainer {
	position: relative;
	height: 13px;
}
