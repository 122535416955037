@import '_colors.scss';

.main {
	display: grid;
	grid-template-columns: auto auto;
	align-items: center;
	border-top: 1px solid $skyBlue;

	&:first-child {
		border-top: none;
	}

	.label {
		color: $lightSky;
		margin-left: 7px;
	}

	.value {
		text-align: right;
	}
}
