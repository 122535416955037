// Style Guide: // https://xd.adobe.com/spec/01b8222b-ffff-4b01-6bbd-64965667b62f-7789/screen/561746fb-37f9-482f-9cdd-ebf8cccc578a/Colour/
@import '_colors.scss';

// Buttons

.button {
	cursor: pointer;
	height: 3rem;
}

.secondaryButton {
	@extend .button;
	background-color: $darkSky;
	color: white;
}

.outlineButton {
	background-color: $white;
	border: 1px solid $darkBlue;
	color: $darkBlue;
}

.transparentButton {
	outline: none;
	background-color: transparent;
	padding: 0;
}

// Small buttons

.smallButton {
	@extend .button;
	height: 2rem;
	padding: 0 0.5rem;
}

.smallSecondaryButton {
	@extend .secondaryButton, .smallButton;
}

.smallTransparentButton {
	@extend .transparentButton, .smallButton;
}

.smallOutlineButton {
	@extend .outlineButton, .smallButton;
}

.popup {
	display: block;
	position: absolute;
	background-color: white;
	border-width: 1px;
	border-color: $lightSky;
	border-style: solid;
	border-radius: 6px;
	padding: 0.5rem;
	color: $darkBlue;
	z-index: 1;
}
