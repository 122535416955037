@import '_colors';
@import '_components';

.main {
	background-color: $darkBlue;
	color: white;
	display: grid;
	grid-template-areas:
		'panel1'
		'panel2'
		'panel3';
	grid-template-columns: repeat(auto-fit, minmax(300px, auto));
	grid-area: summary;
}

.summarySection {
	display: grid;
	grid-template-rows: repeat(4, 40px);
	margin-right: 25px;
}

.viewTimelinebutton {
	@extend .smallTransparentButton;
	text-align: right;
	color: $skyBlue;
}

.clockImage {
	padding-right: 0.5rem;
	vertical-align: middle;
}
