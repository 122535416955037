.main {
	position: absolute;
	width: 13px;
	height: 13px;
	transform: rotate(45deg);
	background-color: white;
}

.main:hover {
	box-shadow: 0 0 0 2px #fff, 0 0 0 8px #ffffff19;
	cursor: pointer;
}

.selected {
	box-shadow: 0 0 0 2px #fff, 0 0 0 8px #ffffff19, 0 0 0 16px #ffffff10;
}
