header {
	font-family: 'Booster FY', sans-serif;
}

body {
	font-family: 'Booster FY', sans-serif;
}

h1 {
	font-weight: 200;
	font-size: 3rem;
}

h2 {
	font-weight: 200;
	font-size: 2rem;
}

h3 {
	font-weight: 400;
	font-size: 1.375rem;
}

h4 {
	font-weight: 400;
	font-size: 1.125rem;
}

p {
	font-weight: 400;
	font-size: 1rem;
}

h6 {
	font-weight: 400;
	font-size: 0.75rem;
}

input {
	font-family: 'Booster FY', sans-serif;
	font-size: 1rem;
	color: #001e62;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	border: 1px solid #cbdbef;
	border-radius: 4px;
	height: 3rem;
	padding: 0 0.5rem;
}

input:focus {
	outline: none;
	border-color: #cbdbef;
	box-shadow: 0 0 6px #009cde;
	transition: box-shadow linear 150ms;
}

button {
	font-family: 'Booster FY', sans-serif;
	font-size: 1rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	border: 0;
	border-radius: 4px;
	padding: 0 1rem;
}
