@import '_colors.scss';
@import '_components';

.main {
	display: grid;
	grid-template-columns: auto auto auto;
	grid-template-rows: auto;
	grid-template-areas: 'input button close';
	align-items: center;
	padding: 0.5rem;
}

input {
	grid-area: input;
	width: 8rem;
	padding: 0 0.5rem;
	margin-right: 0.5rem;
}

/* Hide HTML5 Up and Down arrows. */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.lookupButton {
	@extend .secondaryButton;
	grid-area: button;
	width: 7rem;
	cursor: pointer;
}

.closeButton {
	grid-area: close;
	height: 1rem;
	width: 1rem;
	margin: 1rem;
	margin-right: 0.5rem;
	cursor: pointer;
}
