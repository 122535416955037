@import '_colors';
@import '_components';

.main {
	@extend .popup;
	color: $gray;
	top: 58px;
	min-width: 8rem;
	height: 3rem;
	padding: 1rem 0.5rem;
}
