@import '_colors';

.main {
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	background-color: white;
	grid-template-columns: 10rem repeat(13, 1fr);
	width: 100%;
	color: $darkBlue;
}
